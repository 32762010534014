import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useWindowSize } from "react-use"
import { Link, navigate } from "gatsby"

import { TextInput, PasswordInput, BoolInput } from "../UserProfileFormBlocks"
import Tali from "../Tali"

import { SignUpTeacherLayout } from "../../styles/common"

const SignUpTeacherDisplay = ({
  registrationErrorMessage,
  handleChange,
  handleSubmit,
  toggleSignInFlag,
}) => {
  const { width } = useWindowSize()

  return (
    <SignUpTeacherLayout as="form" onSubmit={handleSubmit}>
      <Tali
        size={(width / 800) * 0.8}
        mood="happy"
        position="top"
        float={false}
        messageStyle="glow"
      >
        <p className="tali-question">
          Alternatively, you can create a new account for your class here.
        </p>
      </Tali>
      <span style={{ color: "red" }}>{registrationErrorMessage}</span>
      <TextInput
        handleChange={handleChange}
        id="teacher-name"
        name="name"
        label="Name"
        required
        placeholder="Name"
        type="text"
      />
      <TextInput
        handleChange={handleChange}
        id="teacher-email-signup"
        name="email"
        label="E-Mail"
        required
        placeholder="E-Mail"
        error={"test"}
        type="email"
      />
      <PasswordInput
        handleChange={handleChange}
        id="teacher-password-signup"
        name="password"
        label="Password"
        required
        placeholder="Password"
      />
      <TextInput
        handleChange={handleChange}
        id="teacher-school"
        name="school"
        label="School"
        required
        placeholder="School"
        type="text"
      />
      {/* <div>
                <BoolInput
                    handleChange={handleChange}
                    id="teacher-privacy-accept"
                    name="terms-accept"
                    label={
                        <span>
                            I have read the{" "}
                            <Link className="document-link" to="/privacy-policy">
                                privacy policy
                            </Link>.
                        </span>
                    }
                    required
                />
                <BoolInput
                    handleChange={handleChange}
                    id="teacher-terms-accept"
                    name="terms-accept"
                    label={
                        <span>
                            Our{" "}
                            <Link className="document-link" to="/terms-of-use">
                                general terms and conditions and terms of use
                            </Link>
                            {" "} apply.
                        </span>
                    }
                    required
                />
            </div> */}
      <nav>
        <button
          className="button back-button button--medium"
          onClick={() => navigate("/")}
        >
          Back
        </button>
        <button
          style={{ marginLeft: "1rem" }}
          onClick={handleSubmit}
          className="continue-button button button--medium"
        >
          Register
        </button>
      </nav>
      <button
        onClick={() => {
          toggleSignInFlag()
        }}
        className="button sign-in-button button--medium"
      >
        {"I already have an account."}
      </button>
    </SignUpTeacherLayout>
  )
}

SignUpTeacherDisplay.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default SignUpTeacherDisplay
