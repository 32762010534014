import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useWindowSize } from "react-use"

import { SignInTeacherLayout } from "../../styles/common"

import { TextInput, PasswordInput } from "../UserProfileFormBlocks"
import Tali from "../Tali"
import { Link, navigate } from "gatsby"

const SignInTeacherDisplay = (props) => {
  const { width } = useWindowSize()
  return (
    <SignInTeacherLayout as="form" onSubmit={props.handleSubmit}>
      <Tali
        size={(width / 800) * 0.8}
        mood="happy"
        position="top"
        float={false}
        messageStyle="glow"
      >
        <p className="tali-question">Welcome! Please log in to get started!</p>
      </Tali>
      <TextInput
        handleChange={props.handleChange}
        id="teacher-email"
        name="email"
        label="E-Mail"
        required
        placeholder="E-Mail"
        type="email"
      />
      <PasswordInput
        handleChange={props.handleChange}
        id="teacher-password"
        name="password"
        label="Password"
        required
        placeholder="Password"
      />
      <nav>
        <button
          className="button back-button button--medium"
          onClick={() => navigate("/")}
        >
          Back
        </button>
        <button
          style={{ marginLeft: "1rem" }}
          onClick={(e) => {
            props.handleSubmit(e)
            console.log(props)
          }}
          className="continue-button button button--medium"
          disabled={props.signInTeacherMutation.isLoading}
        >
          {props.signInTeacherMutation.isLoading ? "Loading..." : "Continue"}
        </button>
      </nav>
      <div style={{ color: "white" }}>
        {props.signInTeacherMutation.isSuccess &&
          (props.signInTeacherMutation.data.teacherSignIn.jwtToken ? (
            <div>Login successfull!</div>
          ) : (
            <div>False login data!</div>
          ))}
        {props.signInTeacherMutation.isError && (
          <div>{props.signInTeacherMutation.error}</div>
        )}
      </div>
      <button
        onClick={() => {
          props.toggleSignInFlag()
        }}
        className="button sign-up-button button--medium"
      >
        {"No account yet? Sign up!​"}
      </button>
    </SignInTeacherLayout>
  )
}

SignInTeacherDisplay.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  signInTeacherMutation: PropTypes.object.isRequired,
}

export default SignInTeacherDisplay
