import useLocalStorage from "react-use-localstorage"

/* eslint-disable react-hooks/rules-of-hooks */
const useSSRStorage = (key, initial) =>
  typeof window === "undefined"
    ? [initial, () => undefined]
    : useLocalStorage(key, initial)
/* eslint-enable react-hooks/rules-of-hooks */

export default useSSRStorage
